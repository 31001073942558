import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './Brand.module.scss';

import brandLogo from '../../images/rottlan_new_logo.svg';

export const Brand = () => {
  return (
    <section id="brand">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <div className={styles.brandContainer}>
              <img className={styles.logo} src={brandLogo} alt="Branding for Rottlan, skull with pirate hat" />
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};
