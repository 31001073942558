import React from 'react';

import styles from './UpcommingDate.module.scss';

interface Props {
  prefix: string;
  date: string;
}

export const UpcommingDate: React.FC<Props> = ({prefix, date}) => {
  return (
    <div className={styles.container}>
      <div className={styles.prefix}>{prefix}: </div>
      <div className={styles.date}>{date}</div>
    </div>
  );
}