import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './Event.module.scss';
import eventLogo from '../../images/Rottlan2020PacMan.png';

export const Event = () => {
  return (
    <section id="event">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <div className={styles.event}>
              {/* <img className={styles.logo} src={eventLogo} alt="Event logo, text in pacman font" />
              <span className={styles.subText}>Waka Waka!</span> */}
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};
