import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CountdownLib, { TimeRemaining } from 'countdown-lib';

import styles from './Countdown.module.scss';

export const Countdown = () => {
  const [timeRemaining, setTimeRemaining] = useState({} as TimeRemaining);
  const [isComplete, setIsComplete] = useState(false);

  const countdown = new CountdownLib(new Date(2024, 7, 28, 17, 30));

  useEffect(() => {
    if (isComplete) return;

    countdown.startCounter((timeRemaining: TimeRemaining, isComplete: boolean) => {
      setTimeRemaining(timeRemaining);
      setIsComplete(countdown.isComplete);
    });

    return () => countdown.stopCounter(countdown.interval);
  }, [countdown, isComplete]);

  return (
    <section id="countdown">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <div className={styles.countdown_container}>
              {timeRemaining.days !== undefined && (
                <div className={styles.timer}>
                  <div className={styles.time}>{countdown.addLeadingZeros(timeRemaining.days)}</div>
                  <div className={styles.label}>days</div>
                </div>
              )}
              {timeRemaining.hours !== undefined && (
                <div className={styles.timer}>
                  <div className={styles.time}>{countdown.addLeadingZeros(timeRemaining.hours)}</div>
                  <div className={styles.label}>hours</div>
                </div>
              )}
              {timeRemaining.minutes !== undefined && (
                <div className={styles.timer}>
                  <div className={styles.time}>{countdown.addLeadingZeros(timeRemaining.minutes)}</div>
                  <div className={styles.label}>minutes</div>
                </div>
              )}
              {timeRemaining.seconds !== undefined && (
                <div className={styles.timer}>
                  <div className={styles.time}>{countdown.addLeadingZeros(timeRemaining.seconds)}</div>
                  <div className={styles.label}>seconds</div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};
