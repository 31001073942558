import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import styles from './Header.module.scss';

import logo from '../../images/rottlan_new_text_logo.svg';

export const Header = () => {
  return (
    <header id="header" className={styles.headerContainer}>
      <Grid>
        <Row>
          <Col xs={12} sm={3}>
            <h1 className={styles.title}>Rottlan</h1>
            <div className={styles.logoContainer}>
              <img className={styles.logo} src={logo} alt="Rottlan logo, only text" />
            </div>
          </Col>
          <Col xs={12} sm={9}>
            <nav className={styles.navigation}>
              <ul className={styles.list}>
                <li className={styles.items}></li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Grid>
    </header>
  );
};
